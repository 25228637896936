import React from 'react';
import '../components/styles/faq.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const questionsAnswers = [
  {
    question: 'Suis-je endormi pendant la séance ?',
    answer: 'Non, vous serez entre l’état de veille et de sommeil.'
  },
  {
    question: 'Pourrais-je me réveiller ? ',
    answer: "Oui, car lors d'une séance d'hypnose vous n'êtes pas endormi."
  },
  {
    question: 'Est ce que je dévoilerai des secrets sur ma vie privée sous hypnose ?',
    answer: 'Non, c’est vous qui me donnez les informations de manière consciente. <br />De plus, votre inconscient a un rôle de garde fou. <br />Vous ne pouvez pas révéler ce que vous ne souhaitez pas.'
  },
  {
    question: 'Pouvez-vous lire dans mes pensées ?',
    answer: 'Non, l’hypnothérapeute n’a pas ce don. <br />Il applique son savoir.'
  },
  {
    question: 'Pourrais-je rentrer chez moi immédiatement après la séance ?',
    answer: 'Bien sûr.<br />Le retour à l’état de veille se fera en douceur.<br />De plus, vous repartirez en pleine forme et avec un nouvel affect.'
  }
];
const FAQ = () => {
  const handleClick = ({ currentTarget }) => {
    currentTarget.querySelector('.question').classList.toggle('open');
    currentTarget.querySelector('.answer').classList.toggle('active');
  };
  return (
    <Layout>
      <Seo />
      <HeaderRandom />
      <div id='title'>
        <h1>FAQ</h1>
      </div>

      <div className='division'>
        <div className='container'>
          <div className='faq'>
            {
                questionsAnswers.map(({ question, answer }, key) => {
                  return (
                    <div className='tabFaq' role='button' tabIndex={0} key={key} onClick={(e) => { handleClick(e, key); }} aria-hidden='true'>
                      <div className='question'><p>{question}<i className='fas fa-chevron-down' /></p> </div>
                      <div className='answer'><p><span dangerouslySetInnerHTML={{ __html: answer }} /></p></div>
                    </div>
                  );
                })
            }
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default FAQ;
